import Calculator from "./Calculator";

function App() {
  return (
    <div>
      <Calculator />
      <p>created by lugen</p>
    </div>
  );
}

export default App;
